export { toBRL } from './currencyFormatter';

export const getEmpresaIdent = () => {
    return window.location.host.split('.')[0]
}

export const randomArray = (size: number = 9) => {
    return Array.from({ length: Math.floor(Math.random() * size) + 1 }, (v, k) => k);
}

export const DRFtoMessage = (message: object) => {
    return Object.entries(message).map(item => {
        return `${item[0]}: ${item[1]}`
    }).join(', ')
}