import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import { toBRL } from "src/utils/currencyFormatter";

interface Props {
    produtos?: any;
}

const ProductTable: React.FC<Props> = (props) => {
    const { produtos } = props;
    return (
        <div>
            <Grid container>
                <Grid xs={1} item>
                    <Typography>Qtd</Typography>
                </Grid>
                <Grid xs={8} item>
                    <Typography>Item</Typography>
                </Grid>
                <Grid xs={3} item>
                    <Typography style={{ textAlign: "right" }}>
                        Valor
                    </Typography>
                </Grid>
            </Grid>
            {produtos.map((prod: any, key: number) => {
                return (
                    <div key={key}>
                        <Grid container>
                            <Grid xs={1} item>
                                <Typography>{prod.quantidade}</Typography>
                            </Grid>
                            <Grid xs={8} item>
                                <Typography>{prod.nome}</Typography>
                            </Grid>
                            <Grid xs={3} item>
                                <Typography style={{ textAlign: "right" }}>
                                    {toBRL(
                                        prod.valor * prod.quantidade ||
                                            prod.valor_total
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                );
            })}
        </div>
    );
};

ProductTable.prototype = {
    produtos: PropTypes.array,
};

export default ProductTable;
