import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import RegisterSVG from "./registerSVG";
import { isMobile } from "react-device-detect";
import { register } from "src/data/api";
import { useHistory, useParams } from "react-router-dom";
import { showMessage } from "src/utils/dialogs";
import { Button, TextField } from "src/components";
import { getEmpresaIdent } from "src/utils";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: isMobile ? theme.spacing(1) : theme.spacing(4),
    },
    headerContainer: {
        display: "flex",
        marginBottom: theme.spacing(2),
        flexDirection: isMobile ? "column" : "row",
    },
    headerTitle: {
        marginLeft: theme.spacing(2),
    },
    inputContainer: {
        marginBottom: theme.spacing(3),
    },
    input: {
        marginTop: theme.spacing(2),
    },
}));

interface Props {}

const RegisterForm: React.FC = () => {
    const classes = useStyles();
    const [nome, setNome] = useState("");
    const [cpf, setCPF] = useState("");
    const [email, setEmail] = useState("");
    const [telefone, setTelefone] = useState("");
    const [senha, setSenha] = useState("");
    const [csenha, setCSenha] = useState("");
    const history = useHistory();
    const { to } = useParams();
    const [loading, setLoading] = useState(false);

    function doRegister() {
        (async () => {
            setLoading(true);
            if (senha !== csenha) {
                showMessage("Senhas não coincidem");
                setLoading(false);
            } else {
                register({
                    username: email,
                    password: senha,
                    cpf: cpf.replace(/\D/g, ""),
                    email,
                    telefone: telefone.replace(/\D/g, ""),
                    nome,
                    registered_in: getEmpresaIdent()
                })
                    .then((token) => {
                        if (token?.token) {
                            if (to) {
                                history.push(
                                    "/" + (to as String).replace(".", "/")
                                );
                            } else {
                                history.push("/");
                            }
                        }
                    })
                    .catch((err) => {
                        //faz nada
                        showMessage("Erro ao cadastrar, verifique os campos.");
                        setLoading(false);
                    });
            }
        })();
    }

    return (
        <div>
            <Paper className={classes.container}>
                <div className={classes.headerContainer}>
                    <RegisterSVG />
                    <div className={classes.headerTitle}>
                        <Typography variant="h5">Novos Clientes</Typography>
                        <Typography variant="body1">
                            Faça seu cadastro para prosseguir com sua compra.
                        </Typography>
                    </div>
                </div>
                <div className={classes.inputContainer}>
                    <TextField
                        label="Nome"
                        fullWidth
                        variant="filled"
                        value={nome}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setNome(e.target.value)
                        }
                        maskprops={{
                            mask: /^([a-z]+( )?)+$/i,
                            placeholder: "José Antônio da Silva",
                        }}
                    />
                    <TextField
                        className={classes.input}
                        label="CPF"
                        fullWidth
                        variant="filled"
                        value={cpf}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setCPF(e.target.value)
                        }
                        maskprops={{
                            mask: "000.000.000-00",
                            placeholder: "000.000.000-00",
                        }}
                    />
                    <TextField
                        className={classes.input}
                        label="Email"
                        fullWidth
                        variant="filled"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setEmail(e.target.value)
                        }
                        type="email"
                        maskprops={{
                            placeholder: "exemplo@exemplo.com.br",
                        }}
                    />
                    <TextField
                        className={classes.input}
                        label="Telefone"
                        fullWidth
                        variant="filled"
                        value={telefone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setTelefone(e.target.value)
                        }
                        maskprops={{
                            mask: [
                                { mask: "(00) 0000-0000" },
                                { mask: "(00) {9} 0000-0000" },
                            ],
                            placeholder: "(21) 9 9999-9999",
                        }}
                    />
                    <TextField
                        className={classes.input}
                        label="Senha"
                        fullWidth
                        variant="filled"
                        value={senha}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setSenha(e.target.value)
                        }
                        type="password"
                    />
                    <TextField
                        className={classes.input}
                        label="Confirmar Senha"
                        fullWidth
                        variant="filled"
                        value={csenha}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setCSenha(e.target.value)
                        }
                        type="password"
                    />
                </div>
                <div>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={doRegister}
                        loading={loading}
                    >
                        Faça seu cadastro
                    </Button>
                </div>
            </Paper>
        </div>
    );
};

export default RegisterForm;
