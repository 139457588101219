import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
    IconButton,
    Typography,
    Grid,
    RadioGroup,
    TextField,
    Divider,
} from "@material-ui/core";
import { ArrowBack, Send } from "@material-ui/icons";
import { AppBar, MesaBox, ToolBarSpace } from "src/components";
import TabItems from "./tabitems";
import FormaRadio from "./formaRadio";
import EnderecoBar from "src/pages/endereco/enderecoBar";
import { Button } from "src/components";
import { isMobile } from "react-device-detect";
import {
    useCarrinho,
    useEmpresa,
    useEnderecos,
    usePedido,
    useProdutos,
    useLoggedUser,
} from "src/data/api";
import { api } from "src/data";
import { getEmpresaIdent, toBRL } from "src/utils";
import { showMessage } from "src/utils/dialogs";
import { sumBy } from "lodash";
import { carrinhoInterface, produtoInterface } from "src/data/interfaces";
import Skeleton from "react-loading-skeleton";

const useStyles = makeStyles((theme) => ({
    appBarTitle: {
        marginLeft: theme.spacing(2),
    },
    leftSideWrapper: {
        display: "flex",
        flexDirection: "column",
    },
    mainTitle: {
        marginBottom: theme.spacing(2),
        display: isMobile ? "block" : "flex",
    },
    container: {
        marginTop: theme.spacing(1),
    },
    mainTitleStatus: {
        marginLeft: isMobile ? 0 : theme.spacing(2),
    },
    titleFrist: {
        marginBottom: theme.spacing(2),
    },
    title: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    formaItem: {
        marginBottom: theme.spacing(2),
    },
    bottomAppBar: {
        top: "auto",
        bottom: 0,
    },
    bottomAppBarContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
}));

interface Props {}

const Pedido: React.FC<Props> = (props) => {
    const { id } = useParams();
    const classes = useStyles();
    const history = useHistory();
    const [forma, setForma] = useState("0");
    const { data: pedido } = usePedido(id);
    const { data: endereco } = useEnderecos(pedido?.endereco);
    const [troco, setTroco] = useState(0);
    const [loading, setLoading] = useState(false);
    const { data: carrinho } = useCarrinho();
    const { data: produtos } = useProdutos({
        id__in: carrinho?.map((item: carrinhoInterface) => item.id).join(","),
    });
    const [total, setTotal] = useState(0);
    const { data: empresa } = useEmpresa(getEmpresaIdent());
    const { data: user } = useLoggedUser();

    useEffect(() => {
        const totalV = (pedido?.produtos || carrinho)?.map(
            (item: carrinhoInterface, key: number) => {
                const produto = produtos?.filter(
                    (prod: produtoInterface) => prod.id === item.id
                )[0];
                return {
                    value: (produto?.valor || 0) * item.quantidade,
                };
            }
        );
        setTotal(sumBy(totalV, "value"));
    }, [carrinho, produtos, pedido]);

    const handleChangeForma = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selected = event.target.value;
        if (selected === "1") {
            showMessage(
                "Troco para quanto?",
                `Total: ${toBRL(total)}`,
                (ret: number) => {
                    const troco = ret - total;
                    if (troco >= 0) {
                        setTroco(troco);
                        setForma(selected);
                    } else {
                        showMessage(
                            "O valor do pagamento deve ser maior que o valor total do pedido",
                            "Erro"
                        );
                    }
                },
                false,
                {
                    label: `Troco`,
                    maskprops: {
                        mask: "000",
                        placeholder: "R$0,00",
                    },
                }
            );
        } else {
            setForma(selected);
        }
    };

    useEffect(() => {
        if (pedido) {
            if (!Number(id) && user) {
                //Joga para seleção de mesa se for garçom, e se for usuário joga para
                //Seleção de endereço
                if (user.is_garcom) {
                    if (!pedido.mesa) history.push("/mesa");
                } else {
                    if (!pedido.endereco) history.push("/endereco");
                }
            } else {
                if (pedido.status === "finalizado") {
                    //Pedido OK
                    localStorage.removeItem("pedido");
                    history.push(`/`);
                }
            }
        }
        //eslint-disable-next-line
    }, [endereco, pedido, user]);

    const finalizar_pedido = (comanda: string) => {
        (async () => {
            setLoading(true);
            //Finaliza o pedido
            try {
                const ped = await api().post("/pedido/finalizar/", {
                    ...pedido,
                    troco,
                    forma: Number(forma),
                    comanda,
                    empresa: getEmpresaIdent(),
                });
                if (ped.data.id) {
                    //Pedido OK
                    localStorage.removeItem("pedido");
                    history.push(`/pedido/${ped.data.id}`);
                } else {
                    //Pedido deu erro
                    showMessage("Erro ao enviar pedido: " + ped.data);
                    setLoading(false);
                }
            } catch (error) {
                //Pedido deu erro
                showMessage("Erro ao enviar pedido: Offline");
                setLoading(false);
            }
        })();
    };

    const returnPedidoStatus = (sts: string) => {
        switch (sts) {
            case "realizado":
                return "Aguardando Confirmação";
            case "em_processo":
                return "Em Andamento";
            case "em_transito":
                return "Saiu para entrega";
            case "cancelado":
                return "Cancelado";
            case "finalizado":
                return "Finalizado";
            case "enviar_mesa":
                return "Enviando para Mesa...";
            case "enviado_mesa":
                return "Registrado na mesa.";
            default:
                return "???";
        }
    };

    return (
        <div>
            <AppBar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => {
                        console.log(pedido?.status);
                        if (
                            ["enviar_mesa", "enviado_mesa"].includes(
                                pedido?.status
                            )
                        ) {
                            history.push("/");
                        } else {
                            history.push("/carrinho");
                        }
                    }}
                >
                    <ArrowBack />
                </IconButton>
                <Send />
                <div className={classes.appBarTitle}>
                    <Typography variant="h6">
                        {id === "novo"
                            ? "Finalização do Pedido"
                            : `Pedido #${id} em andamento`}
                    </Typography>
                </div>
            </AppBar>
            <ToolBarSpace />
            {id === "novo" ? null : (
                <>
                    <div className={classes.mainTitle}>
                        <Typography variant={isMobile ? "h6" : "h3"}>
                            Status do Pedido:{" "}
                        </Typography>
                        <Typography
                            className={classes.mainTitleStatus}
                            color="primary"
                            variant={isMobile ? "h6" : "h3"}
                        >
                            <b>{returnPedidoStatus(pedido?.status)}</b>
                        </Typography>
                    </div>
                    <Divider />
                </>
            )}
            <div className={classes.container}>
                <Grid container spacing={4}>
                    <Grid item xs={isMobile ? 12 : user?.is_garcom ? 12 : 7}>
                        <div className={classes.leftSideWrapper}>
                            <Typography
                                className={classes.titleFrist}
                                variant="h5"
                            >
                                Os seguintes itens
                            </Typography>
                            <TabItems produtos={pedido?.produtos} />
                            <Typography className={classes.title} variant="h5">
                                {user?.is_garcom
                                    ? "Será entregue na mesa"
                                    : "Será entregue em"}
                            </Typography>
                            {user?.is_garcom ? (
                                <MesaBox
                                    id={pedido?.mesa}
                                    forceMobile
                                    onClick={() => history.push("/mesa")}
                                    disabled={id !== "novo"}
                                />
                            ) : (
                                <EnderecoBar
                                    onClick={() => history.push("/endereco")}
                                    disabled={id !== "novo"}
                                    endereco={endereco}
                                />
                            )}
                        </div>
                    </Grid>
                    {user?.is_garcom &&
                    !(empresa && empresa[0].nao_confirmar) ? null : (
                        <Grid
                            item
                            xs={isMobile ? 12 : user?.is_garcom ? 12 : 7}
                            style={{
                                marginBottom: isMobile
                                    ? 100
                                    : user?.is_garcom
                                    ? 100
                                    : 0,
                            }}
                        >
                            <Typography
                                className={classes.titleFrist}
                                variant="h5"
                            >
                                Forma de Pagamento
                            </Typography>
                            <RadioGroup
                                aria-label="forma"
                                name="forma"
                                onChange={handleChangeForma}
                                value={
                                    id !== "novo"
                                        ? pedido?.forma
                                            ? String(pedido?.forma)
                                            : "1"
                                        : forma
                                }
                            >
                                {empresa ? (
                                    empresa[0]?.formas.map(
                                        (forma: any, key: number) => (
                                            <FormaRadio
                                                key={key}
                                                forma={forma.descricao}
                                                value={String(forma.forma)}
                                                icone={forma.icone}
                                                className={classes.formaItem}
                                                disabled={id !== "novo"}
                                            />
                                        )
                                    )
                                ) : (
                                    <Skeleton width={"100%"} height={300} />
                                )}
                            </RadioGroup>
                            {id === "novo" && forma === "1" ? (
                                <>
                                    <Typography
                                        className={classes.title}
                                        variant="h5"
                                    >
                                        Digite o troco abaixo
                                    </Typography>
                                    <TextField
                                        label="Troco"
                                        fullWidth
                                        variant="filled"
                                        value={troco}
                                        onChange={(e) => {
                                            if (
                                                !isNaN(Number(e.target.value))
                                            ) {
                                                setTroco(
                                                    Number(e.target.value)
                                                );
                                            }
                                        }}
                                    />
                                </>
                            ) : null}
                        </Grid>
                    )}
                </Grid>
            </div>
            {id === "novo" ? (
                <AppBar className={classes.bottomAppBar}>
                    <div className={classes.bottomAppBarContainer}>
                        <Button
                            size="large"
                            variant="outlined"
                            color="secondary"
                            onClick={() => history.push("/carrinho")}
                        >
                            Voltar ao Carrinho
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                showMessage(
                                    "Digite o numero da comanda",
                                    ``,
                                    finalizar_pedido,
                                    false,
                                    {
                                        label: `Número da Comanda`,
                                        maskprops: {
                                            mask: "0000",
                                            placeholder: "Ex: 12345",
                                        },
                                    }
                                );
                            }}
                            loading={loading}
                        >
                            FINALIZAR
                        </Button>
                    </div>
                </AppBar>
            ) : null}
        </div>
    );
};

export default Pedido;
