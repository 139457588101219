import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Paper,
    Radio,
    Typography,
    FormControlLabel,
    Icon,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    formaContainer: {
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1),
        width: "100%",
    },
    icon: {
        marginRight: theme.spacing(1),
    },
}));

interface MainProps {
    value: string;
    forma: string;
    icone: string;
    className?: string;
    disabled?: boolean;
}

interface RadioProps {
    forma: string;
    icone: string;
}

const FormaRadio: React.FC<RadioProps> = (props) => {
    const classes = useStyles();
    return (
        <Paper className={classes.formaContainer}>
            <Radio color="primary" {...props} />
            <Icon className={classes.icon}>{props.icone}</Icon>
            <Typography>{props.forma}</Typography>
        </Paper>
    );
};

const MainFormaRadio = (props: MainProps) => {
    return (
        <FormControlLabel
            disabled={props.disabled}
            className={props.className}
            value={props.value}
            control={<FormaRadio forma={props.forma} icone={props.icone} />}
            label={null}
            style={{ marginRight: 0 }}
        />
    );
};

export default MainFormaRadio;
