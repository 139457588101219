import { mutate } from "swr";

export function showMessage(
    mensagem: string,
    titulo: string = "Mensagem do Sistema",
    func: any = undefined,
    confirm: boolean = false,
    input: any = undefined
) {
    //Exibe uma mensagem na tela
    mutate("dialog", {
        open: true,
        message: mensagem,
        title: titulo,
        type: confirm ? 1 : input ? 2 : 0,
        func: func,
        inputProps: input,
    });
}
