import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActionArea, Typography } from "@material-ui/core";
import { sumBy } from "lodash";
import { toBRL } from "../../utils/currencyFormatter";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        padding: theme.spacing(2),
        justifyContent: "space-between",
    },
    focused: {
        backgroundColor: "rgba(0, 0, 0, 0.3)",
    },
    leftSide: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    rightSide: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "space-between",
    },
    pedidoStatus: {
        padding: theme.spacing(1),
        borderRadius: 10,
        backgroundColor: "blue",
        color: "white",
        fontWeight: "bold",
    },
}));

interface PedidoStatusProps {
    color?: any;
    variant?: any;
    children?: any;
}

export const PedidoStatus: React.FC<PedidoStatusProps> = (props) => {
    const classes = useStyles();
    return (
        <div
            className={classes.pedidoStatus}
            style={{ backgroundColor: props.color }}
        >
            <Typography variant={props.variant ? props.variant : "body1"}>
                {props.children}
            </Typography>
        </div>
    );
};

interface PedidoBarProps {
    pedido?: any;
    className?: any;
    onClick?: any;
    focused?: any;
}

const PedidoBar: React.FC<PedidoBarProps> = (props) => {
    const classes = useStyles();
    const { pedido } = props;

    function renderStatus(status: any) {
        switch (status) {
            case "realizado":
                return <PedidoStatus color="#ff8c66">Pendente</PedidoStatus>;
            case "em_processo":
                return <PedidoStatus color="#ffcc66">Processando</PedidoStatus>;
            case "em_transito":
                return <PedidoStatus color="#66d9ff">Em Transito</PedidoStatus>;
            case "cancelado":
                return <PedidoStatus color="#ff6666">Cancelado</PedidoStatus>;
            case "finalizado":
                return <PedidoStatus color="#4bb94b">Concluído</PedidoStatus>;
            case "em_avaliacao":
                return <PedidoStatus color="#ff8c66">Aguardando</PedidoStatus>;
            case "enviado_mesa":
                return (
                    <PedidoStatus color="#ff8c66">
                        Enviado para Mesa.
                    </PedidoStatus>
                );
            case "enviar_mesa":
                return (
                    <PedidoStatus color="#ff8c66">
                        Enviando para mesa...
                    </PedidoStatus>
                );
            default:
                break;
        }
    }

    return (
        <Card className={props.className}>
            <CardActionArea onClick={props.onClick}>
                <div
                    className={`${classes.container} ${
                        props.focused ? classes.focused : ""
                    }`}
                >
                    <div className={classes.leftSide}>
                        <Typography
                            variant="h5"
                            style={{ wordBreak: "break-word" }}
                        >
                            {pedido.user_nome}
                        </Typography>
                        <Typography variant="body1"># {pedido.id}</Typography>
                        <Typography variant="body1">
                            {pedido.produtos.length} Itens
                        </Typography>
                    </div>
                    <div className={classes.rightSide}>
                        {renderStatus(pedido.status)}
                        <Typography variant="h5">
                            {toBRL(
                                sumBy(
                                    pedido.produtos,
                                    (prod: any) => prod.valor * prod.quantidade
                                )
                            )}
                        </Typography>
                    </div>
                </div>
            </CardActionArea>
        </Card>
    );
};

PedidoBar.prototype = {
    pedido: PropTypes.object,
    focused: PropTypes.bool,
    onClick: PropTypes.func,
};

export default PedidoBar;
