import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Paper } from "@material-ui/core";
import { useCarrinho, useProdutos, useLoggedUser } from "src/data/api";
import { toBRL } from "src/utils";
import { sumBy } from "lodash";
import { useHistory } from "react-router-dom";
import { carrinhoInterface, produtoInterface } from "src/data/interfaces";
import { isMobile } from "react-device-detect";
import { showMessage } from "src/utils/dialogs";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.primary.main,
        position: "fixed",
        right: theme.spacing(3),
        bottom: theme.spacing(3),
        padding: theme.spacing(3),
        borderRadius: "16px",
    },
    containerMobile: {
        backgroundColor: theme.palette.primary.main,
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        padding: theme.spacing(2),
    },
    entregaContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    subtotalContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    valueMargin: {
        marginLeft: theme.spacing(6),
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

interface Props {}

const Totalizador: React.FC<Props> = (props) => {
    const classes = useStyles();
    const { data: carrinho } = useCarrinho();
    const { data: produtos } = useProdutos({
        id__in: carrinho?.map((item: carrinhoInterface) => item.id).join(","),
    });
    const { data: user } = useLoggedUser();
    const [total, setTotal] = useState(0);
    const history = useHistory();

    useEffect(() => {
        if (user?.pedido_atual) {
            if (history.location.pathname.split("/")[0] !== "pedido") {
                history.push(`/pedido/${user?.pedido_atual?.id}`);
            }
        }
        //eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        const totalV = carrinho?.map((item: carrinhoInterface, key: number) => {
            const produto = produtos?.filter(
                (prod: produtoInterface) => prod.id === item.id
            )[0];
            return {
                value: (produto?.valor || 0) * item.quantidade,
            };
        });
        setTotal(sumBy(totalV, "value"));
    }, [carrinho, produtos]);

    function registrarPedido() {
        if (total > 0) {
            if (user) {
                history.push("/pedido/novo");
            } else {
                history.push("/login/pedido.novo");
            }
        } else {
            showMessage("Carrinho vazio.");
        }
    }

    return (
        <Paper
            elevation={4}
            className={isMobile ? classes.containerMobile : classes.container}
        >
            <div className={classes.entregaContainer}>
                <Typography color="secondary" variant={isMobile ? "h5" : "h5"}>
                    Entrega
                </Typography>
                <Typography
                    className={classes.valueMargin}
                    color="secondary"
                    variant={isMobile ? "h5" : "h5"}
                >
                    R$0,00
                </Typography>
            </div>
            <div className={classes.subtotalContainer}>
                <Typography color="secondary" variant={isMobile ? "h4" : "h4"}>
                    Subtotal
                </Typography>
                <Typography
                    className={classes.valueMargin}
                    color="secondary"
                    variant={isMobile ? "h4" : "h4"}
                >
                    {toBRL(total)}
                </Typography>
            </div>

            <Button
                size="large"
                color="secondary"
                className={classes.button}
                fullWidth
                onClick={() => history.push("/")}
            >
                CONTINUAR COMPRANDO
            </Button>
            <Button
                size="large"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={registrarPedido}
            >
                FINALIZAR COMPRA
            </Button>
        </Paper>
    );
};

export default Totalizador;
