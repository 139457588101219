import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    Typography,
    ListItem,
    IconButton,
    Grow,
} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import QtButtons from "src/components/ui/productBox/qtButtons";
import { Clear } from "@material-ui/icons";
import { toBRL } from "src/utils";
import { addToCart, useCarrinho } from "src/data/api";
import { produtoCarrinho, carrinhoInterface } from "src/data/interfaces";
import { isMobile } from "react-device-detect";

const columnMedium = isMobile ? 5 : 2;
const columnLarge = isMobile ? 12 : 7;
const columnTiny = isMobile ? 2 : 1;
const imageSize = 68;

interface Props {
    cabecalho?: boolean;
    loading?: boolean;
    item?: produtoCarrinho;
}

interface ItemProps {
    item?: produtoCarrinho;
}

const useStyles = makeStyles((theme) => ({
    productHeadContainer: {
        display: "flex",
        alignItems: isMobile ? "" : "center",
    },
    productHeadTitle: {
        marginLeft: theme.spacing(2),
        wordBreak: "break-word",
    },
}));

const Item: React.FC<ItemProps> = (props) => {
    const classes = useStyles();
    const [quantity, setQuantity] = useState(0);
    const { data: carrinho } = useCarrinho();

    function onAdd() {
        addToCart(props.item?.id || 0, 1);
    }

    function onRemove() {
        if (quantity > 0) {
            addToCart(props.item?.id || 0, -1);
        }
    }

    function onDelete() {
        if (quantity > 0) {
            addToCart(props.item?.id || 0, -quantity);
        }
    }

    useEffect(() => {
        setQuantity(0);
        carrinho?.every((item: carrinhoInterface) => {
            if (item.id === props.item?.id) {
                setQuantity(item.quantidade);
                return false;
            }
            return true;
        });
    }, [carrinho, props.item]);

    return (
        <>
            <Grid item xs={columnLarge}>
                <div className={classes.productHeadContainer}>
                    <img alt="Produto" src={props.item?.foto} width={imageSize} height={imageSize} />
                    <Typography
                        variant="h6"
                        className={classes.productHeadTitle}
                    >
                        {props.item?.nome}
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={columnMedium}>
                <Typography variant="h6">
                    {toBRL(
                        (props.item?.valor || 0) *
                            (props.item?.quantidade || 0) || 0
                    )}
                </Typography>
            </Grid>
            <Grid item xs={columnMedium}>
                <QtButtons
                    onAdd={onAdd}
                    onRemove={onRemove}
                    quantity={quantity}
                />
            </Grid>
            <Grid item xs={columnTiny}>
                <IconButton onClick={onDelete}>
                    <Clear />
                </IconButton>
            </Grid>
        </>
    );
};

const ItemLoading: React.FC = (props) => {
    const classes = useStyles();
    return (
        <>
            <Grid item xs={columnLarge}>
                <div className={classes.productHeadContainer}>
                    <Skeleton width={imageSize} height={imageSize} />
                    <Skeleton
                        className={classes.productHeadTitle}
                        width={isMobile ? 56 : 256}
                        height={28}
                    />
                </div>
            </Grid>
            <Grid item xs={columnMedium}>
                <Skeleton width={isMobile ? 10 : 80} height={28} />
            </Grid>
            <Grid item xs={columnMedium}>
                <Skeleton width={isMobile ? 40 : 160} height={28} />
            </Grid>
            <Grid item xs={columnTiny}>
                <Skeleton width={isMobile ? 5 : 28} height={28} />
            </Grid>
        </>
    );
};

const CarrinhoItem: React.FC<Props> = (props) => {
    return (
        <Grow in={true}>
            {props.cabecalho ? (
                <ListItem>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={columnLarge}>
                                <Typography variant="h4">Produto</Typography>
                            </Grid>
                            <Grid item xs={columnMedium}>
                                <Typography variant="h4">Preço</Typography>
                            </Grid>
                            <Grid item xs={columnMedium}>
                                <Typography variant="h4">Quantidade</Typography>
                            </Grid>
                            <Grid item xs={columnTiny}>
                                <Typography variant="h4"></Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </ListItem>
            ) : (
                <ListItem divider>
                    <Grid item xs={12}>
                        <Grid container alignItems="center">
                            {props.loading ? (
                                <ItemLoading />
                            ) : (
                                <Item item={props.item} />
                            )}
                        </Grid>
                    </Grid>
                </ListItem>
            )}
        </Grow>
    );
};

CarrinhoItem.defaultProps = {
    cabecalho: false,
    loading: false,
};

export default CarrinhoItem;
