import { createMuiTheme } from '@material-ui/core/styles';

export const light = createMuiTheme({
    palette: {
        common: {
            black: "#000",
            white: "#fff"
        },
        background: {
            paper: "#fff",
            default: "#fafafa"
        },
        primary: {
            light: "#ff5f52",
            main: "#c62828",
            dark: "#8e0000",
            contrastText: "#fff",
        },
        secondary: {
            light: "rgba(255, 255, 255, 0.2)",
            main: "#fff",
            dark: "#fff",
            contrastText: "#000",
        },
        error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff"
        },
        text: {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.54)",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)",
        }
    },
})