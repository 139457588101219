import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Zoom, Card, IconButton } from "@material-ui/core";
import { PedidoStatus } from "src/components/pedidobar";
import { usePedido, useCliente, useEnderecos } from "src/data/api";
import { useHistory, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { toBRL } from "src/utils";
import { sumBy } from "lodash";
import ProductTable from "src/components/ui/productTable";
import { AppBar, ToolBarSpace, Button } from "src/components";
import { ArrowBack, ListAlt } from "@material-ui/icons";
import { showMessage } from "src/utils/dialogs";
import { api } from "src/data";

const useStyles = makeStyles((theme) => ({
    appBarTitle: {
        marginLeft: theme.spacing(2),
    },
    listContainer: {
        padding: theme.spacing(1),
        height: "calc(100vh - 90px)",
        paddingRight: theme.spacing(3),
    },
    pedidobar: {
        marginBottom: theme.spacing(1),
    },
    infoCard: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    skeletonMargin: {
        marginBottom: theme.spacing(1),
    },
    buttonsArea: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: theme.spacing(1),
    },
}));

interface Props {}

function renderStatus(status: string) {
    switch (status) {
        case "realizado":
            return (
                <PedidoStatus color="#ff8c66" variant="h4">
                    Pendente
                </PedidoStatus>
            );
        case "em_processo":
            return (
                <PedidoStatus color="#ffcc66" variant="h4">
                    Processando
                </PedidoStatus>
            );
        case "em_transito":
            return (
                <PedidoStatus color="#66d9ff" variant="h4">
                    Em Transito
                </PedidoStatus>
            );
        case "cancelado":
            return (
                <PedidoStatus color="#ff6666" variant="h4">
                    Cancelado
                </PedidoStatus>
            );
        case "finalizado":
            return (
                <PedidoStatus color="#4bb94b" variant="h4">
                    Concluído
                </PedidoStatus>
            );
        case "em_avaliacao":
            return (
                <PedidoStatus color="#ffcc66" variant="h4">
                    Aguardando resposta
                </PedidoStatus>
            );
        default:
            break;
    }
}

const ShowPedido: React.FC<Props> = (props) => {
    const classes = useStyles();
    const { id } = useParams();
    const { data: pedido, mutate: refreshPedidos } = usePedido(id);
    const { data: cliente } = useCliente(pedido?.user);
    const { data: endereco } = useEnderecos(pedido?.endereco);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    function aprovar_pedido(pedido: number) {
        setLoading(true);
        api()
            .post(`/pedido/${pedido}/aprovar/`)
            .then((ped) => {
                refreshPedidos();
                setLoading(false);
            })
            .catch((error) => {
                showMessage("Erro ao aprovar Pedido", "Erro");
                setLoading(false);
            });
    }

    return (
        <div className={classes.listContainer}>
            <AppBar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => history.push("/")}
                >
                    <ArrowBack />
                </IconButton>
                <ListAlt />
                <div className={classes.appBarTitle}>
                    <Typography variant="h6">
                        Pedido de Número {pedido?.id}
                    </Typography>
                </div>
            </AppBar>
            <ToolBarSpace />
            <Zoom in={pedido}>
                <div>
                    {pedido ? (
                        <div>
                            <div className={classes.infoCard}>
                                {renderStatus(pedido.status)}
                            </div>
                            <Card className={classes.infoCard}>
                                <Typography variant="h6">
                                    Pedido #{pedido.id}
                                </Typography>
                                <Typography variant="body1">
                                    Aberto as{" "}
                                    {new Date(
                                        pedido.data_realizado
                                    ).toLocaleString()}
                                </Typography>
                            </Card>
                            <Card className={classes.infoCard}>
                                <Typography variant="h6">Cliente</Typography>
                                {cliente ? (
                                    <div>
                                        <Typography variant="body1">
                                            {cliente?.nome}
                                        </Typography>
                                        <Typography variant="body1">
                                            {cliente?.telefone}
                                        </Typography>
                                    </div>
                                ) : (
                                    <div>
                                        <Skeleton
                                            className={classes.skeletonMargin}
                                            width={340}
                                            height={20}
                                        />
                                        <Skeleton
                                            className={classes.skeletonMargin}
                                            width={160}
                                            height={20}
                                        />
                                    </div>
                                )}
                            </Card>
                            <Card className={classes.infoCard}>
                                <Typography variant="h6">
                                    {pedido?.mesa
                                        ? "Entrega em Mesa " + pedido?.mesa
                                        : "Endereço de Entrega"}
                                </Typography>
                                {endereco ? (
                                    <div>
                                        <Typography variant="body1">
                                            {endereco?.cep}
                                        </Typography>
                                        <Typography variant="body1">{`${endereco?.logradouro}, ${endereco?.numero} - ${endereco?.cidade}, 
                ${endereco?.municipio}, ${endereco?.uf}`}</Typography>
                                        <Typography variant="body1">{`${endereco?.complemento}`}</Typography>
                                    </div>
                                ) : !pedido?.mesa ? (
                                    <div>
                                        <Skeleton
                                            className={classes.skeletonMargin}
                                            width={60}
                                            height={20}
                                        />
                                        <Skeleton
                                            className={classes.skeletonMargin}
                                            width={340}
                                            height={20}
                                        />
                                        <Skeleton
                                            className={classes.skeletonMargin}
                                            width={160}
                                            height={20}
                                        />
                                    </div>
                                ) : undefined}
                            </Card>
                            <Card className={classes.infoCard}>
                                <Typography variant="h6">
                                    Itens do Pedido
                                </Typography>
                                <ProductTable produtos={pedido.produtos} />
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography variant="h5">Total</Typography>
                                    <Typography variant="h5">
                                        {toBRL(
                                            sumBy(
                                                pedido.produtos,
                                                (prod: any) =>
                                                    prod.valor * prod.quantidade
                                            )
                                        )}
                                    </Typography>
                                </div>
                            </Card>
                            {!pedido?.mesa && (
                                <>
                                    <Card className={classes.infoCard}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography variant="h5">
                                                Forma de Pgto.
                                            </Typography>
                                            <Typography variant="h5">
                                                {pedido.forma_nome}
                                            </Typography>
                                        </div>
                                    </Card>
                                    <Card className={classes.infoCard}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Typography variant="h5">
                                                Troco
                                            </Typography>
                                            <Typography variant="h5">
                                                {toBRL(pedido.troco)}
                                            </Typography>
                                        </div>
                                    </Card>
                                </>
                            )}
                            <div className={classes.buttonsArea}></div>
                        </div>
                    ) : (
                        <Typography>Carregando...</Typography>
                    )}
                    <div className={classes.buttonsArea}>
                        <Button
                            color="primary"
                            disabled={pedido?.status !== "em_avaliacao"}
                            variant="contained"
                            size="large"
                            loading={loading}
                            fullWidth
                            onClick={() => aprovar_pedido(pedido.id)}
                        >
                            Aprovar
                        </Button>
                    </div>
                </div>
            </Zoom>
        </div>
    );
};

export default ShowPedido;
