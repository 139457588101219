import React from "react";
import { Button as MButton, CircularProgress } from "@material-ui/core";
import { ButtonTypeMap } from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

type MaterialButton = ButtonTypeMap["props"];

interface Props extends MaterialButton {
    children: React.ReactNode;
    rounded?: boolean;
    lightSecondary?: boolean;
    className?: string;
    onClick?: () => void;
    loading?: boolean;
    style?: any;
}

const useStyles = makeStyles((theme) => ({
    rounded: {
        borderRadius: "100px",
    },
    white: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.common.white,
        "&:hover": {
            color: theme.palette.common.black,
        },
    },
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

const Button: React.FC<Props> = (props) => {
    const classes = useStyles();

    let buttonProps = { ...props };

    //Deleta as Props que não são do Material UI
    delete buttonProps.rounded;
    delete buttonProps.loading;

    return (
        <div
            className={`${classes.wrapper} ${props.className}`}
            style={{ ...props.style, width: props.fullWidth ? "100%" : "" }}
        >
            <MButton
                {...buttonProps}
                className={`${props.rounded ? classes.rounded : null}
                 ${props.lightSecondary ? classes.white : null}
                `}
                disabled={props.loading || props.disabled}
            >
                {props.children}
            </MButton>
            {props.loading && (
                <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                    color={props.color === "primary" ? "primary" : "secondary"}
                />
            )}
        </div>
    );
};

export default Button;
