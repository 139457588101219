import React, { useState } from "react";
import { dialogInterface } from "src/data/interfaces";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { TextField } from "src/components";

interface Props extends dialogInterface {
    setOpen?: (c: boolean) => void;
    setConfirm?: (ret: any) => void;
}

const DLDialog: React.FC<Props> = (props) => {
    const [text, setText] = useState("");

    const handleClose = () => {
        if (props.setOpen) {
            props.setOpen(false);
        }
    };

    const handleConfirm = () => {
        if (props.setConfirm) {
            props.setConfirm(text);
            setText("");
        }
        handleClose();
    };

    return (
        <div>
            <Dialog
                open={props.open || false}
                style={{ borderRadius: 0 }}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <b>{props.message}</b>
                    </DialogContentText>
                    {props.type === 2 ? (
                        <TextField
                            {...props.inputProps}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setText(e.target.value)}
                            value={text}
                        />
                    ) : null}
                </DialogContent>
                <DialogActions>
                    {props.type === 0 || 2 ? (
                        <Button
                            onClick={handleConfirm}
                            color="primary"
                            autoFocus
                        >
                            Ok
                        </Button>
                    ) : (
                        <>
                            <Button onClick={handleClose} color="primary">
                                Não
                            </Button>
                            <Button
                                onClick={handleConfirm}
                                color="primary"
                                autoFocus
                            >
                                Sim
                            </Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DLDialog;
