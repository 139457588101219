import React, { useEffect, useState } from "react";
import { IconButton, Typography, Zoom } from "@material-ui/core";
import { Remove, Add } from "@material-ui/icons/";
import { makeStyles } from "@material-ui/core/styles";
import { isMobile } from "react-device-detect";

const iconSize: number = isMobile ? 24 : 48;

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
    },
    quantity: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    iconButton: {
        width: iconSize,
        height: iconSize,
        color: theme.palette.primary.light,
    },
}));

interface Props {
    onAdd: () => void;
    onRemove: () => void;
    quantity?: number;
}

const QtButtons: React.FC<Props> = (props) => {
    const classes = useStyles();
    const [anim, setAnim] = useState(true);

    useEffect(() => {
        setAnim(false);
    }, [props.quantity]);

    return (
        <div className={classes.container}>
            <IconButton onClick={props.onAdd} className={classes.iconButton}>
                <Add />
            </IconButton>
            <Typography
                variant={isMobile ? "h6" : "h4"}
                className={classes.quantity}
            >
                <Zoom in={anim} onExited={() => setAnim(true)}>
                    <div>{props.quantity}</div>
                </Zoom>
            </Typography>
            <IconButton
                size="small"
                onClick={props.onRemove}
                className={classes.iconButton}
            >
                <Remove />
            </IconButton>
        </div>
    );
};

QtButtons.defaultProps = {
    quantity: 0,
};

export default QtButtons;
