import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
}));

const SimpleAppBar: React.FC<Props> = (props) => {
    return (
        <AppBar className={props.className} position="fixed">
            <Toolbar>{props.children}</Toolbar>
        </AppBar>
    );
};

export const ToolBarSpace: React.FC = () => {
    const classes = useStyles();
    return <div className={classes.toolbar} />;
};

export default SimpleAppBar;
