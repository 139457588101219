import React from "react";
import { Paper, InputBase, IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    input: {
        paddingLeft: theme.spacing(2),
        width: `calc(100% - 48px)`,
        fontSize: "20px",
        height: "48px",
    },
    icon: {
        width: "48px",
    },
}));

interface Props {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: unknown;
}

const MainSearchBar: React.FC<Props> = (props) => {
    const classes = useStyles();
    return (
        <Paper>
            <InputBase
                className={classes.input}
                placeholder="Procure seu produto aqui..."
                inputProps={{ "aria-label": "naked" }}
                onChange={props.onChange}
                value={props.value}
            />
            <IconButton className={classes.icon}>
                <Search />
            </IconButton>
        </Paper>
    );
};

export default MainSearchBar;
