import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Grid, IconButton, Typography, Zoom } from "@material-ui/core";
import { ArrowBack, Restaurant } from "@material-ui/icons";
import { AppBar, ToolBarSpace, Button } from "src/components";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useMesa } from "src/data/api";
import ProductTable from "src/components/ui/productTable";
import { toBRL } from "src/utils";
import { sumBy } from "lodash";
import { showMessage } from "src/utils/dialogs";
import { api } from "src/data";
const useStyles = makeStyles((theme) => ({
    appBarTitle: {
        marginLeft: theme.spacing(2),
    },
    listContainer: {
        paddingRight: theme.spacing(0.5),
        marginTop: theme.spacing(2),
    },
    pedidobar: {
        marginBottom: theme.spacing(1.5),
    },
    infoCard: {
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
    },
    buttonsArea: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: theme.spacing(1),
    },
    bottomButton: {},
}));

interface Props {}

const MesasShow: React.FC<Props> = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);

    const { data: mesa } = useMesa(id);

    function enviar_finalizar_mesa(mesa: number) {
        setLoading(true);
        api()
            .post(`/mesas/${mesa}/concluir/`)
            .then(() => {
                setLoading(false);
                history.push("/mesas/");
            })
            .catch(() => {
                showMessage("Erro ao finalizar Mesa", "Erro");
                setLoading(false);
            });
    }

    return (
        <div>
            <AppBar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => history.push("/mesas/")}
                >
                    <ArrowBack />
                </IconButton>
                <Restaurant />
                <div className={classes.appBarTitle}>
                    <Typography variant="h6">Consumo da Mesa {id}</Typography>
                </div>
            </AppBar>
            <ToolBarSpace />
            <div className={classes.listContainer}>
                <Zoom in={mesa}>
                    <div>
                        <Card className={classes.infoCard}>
                            <Typography variant="h6">
                                Mesa #{mesa?.numero}
                            </Typography>
                            <Typography variant="body1">
                                Aberto as{" "}
                                {new Date(mesa?.abertura).toLocaleString()}
                            </Typography>
                            <Typography variant="body1">
                                Ultima atualização as{" "}
                                {new Date(
                                    mesa?.ultima_alteracao
                                ).toLocaleString()}
                            </Typography>
                        </Card>
                        <Card className={classes.infoCard}>
                            <Typography variant="h6">
                                Consumo da Mesa
                            </Typography>
                            <ProductTable produtos={mesa?.consumo || []} />
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography variant="h5">Total</Typography>
                                <Typography variant="h5">
                                    {toBRL(sumBy(mesa?.consumo, "valor_total"))}
                                </Typography>
                            </div>
                        </Card>
                        <Card className={classes.infoCard}>
                            <Typography variant="h6">
                                Total por Comanda
                            </Typography>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography>Comanda</Typography>
                                <Typography>Valor</Typography>
                            </div>
                            {mesa?.comandas?.map((com: any) => (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Typography>{com.comanda}</Typography>
                                    <Typography>
                                        {toBRL(com.valor_total)}
                                    </Typography>
                                </div>
                            ))}
                        </Card>
                        <Grid
                            container
                            spacing={2}
                            style={{ marginTop: 10 }}
                            justify="flex-end"
                        >
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    disabled={true}
                                    fullWidth
                                    onClick={() => console.log(mesa.numero)}
                                    className={classes.bottomButton}
                                    loading={loading}
                                >
                                    Imprimir Consumo
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    onClick={() =>
                                        enviar_finalizar_mesa(mesa.numero)
                                    }
                                    className={classes.bottomButton}
                                    loading={loading}
                                >
                                    Finalizar Mesa
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Zoom>
            </div>
        </div>
    );
};

export default MesasShow;
