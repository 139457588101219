import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, ToolBarSpace } from "src/components";
import { ArrowBack, ShoppingCart } from "@material-ui/icons";
import { IconButton, Typography, Grid, List } from "@material-ui/core";
import { useProdutos, useCarrinho } from "src/data/api";
import { useHistory } from "react-router-dom";

import CarrinhoItem from "./carrinhoItem";
import Totalizador from "./totalizador";
import { carrinhoInterface, produtoInterface } from "src/data/interfaces";
import { isMobile } from "react-device-detect";
import { randomArray } from "src/utils";
import { isNumber } from "lodash";

const useStyles = makeStyles((theme) => ({
    appBarTitle: {
        marginLeft: theme.spacing(2),
    },
    carrinhoList: {
        marginBottom: theme.spacing(40),
    },
}));

interface Props {}

const Carrinho: React.FC<Props> = (props) => {
    const classes = useStyles();
    const { data: carrinho } = useCarrinho();
    const { data: produtos } = useProdutos({
        id__in: carrinho?.map((item: carrinhoInterface) => item.id).join(","),
    });
    const history = useHistory();
    return (
        <div>
            <AppBar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => history.push("/")}
                >
                    <ArrowBack />
                </IconButton>
                <ShoppingCart />
                <div className={classes.appBarTitle}>
                    <Typography variant="h6">Seu Carrinho</Typography>
                </div>
            </AppBar>
            <ToolBarSpace />
            <List className={classes.carrinhoList}>
                <Grid container>
                    {isMobile ? null : <CarrinhoItem cabecalho />}
                    {(produtos ? carrinho : randomArray(10)).map(
                        (item: carrinhoInterface, key: number) => {
                            if (isNumber(item)) {
                                return <CarrinhoItem key={key} loading />;
                            } else {
                                const produto = produtos?.filter(
                                    (prod: produtoInterface) =>
                                        prod.id === item.id
                                )[0];
                                return (
                                    <CarrinhoItem
                                        key={key}
                                        item={{
                                            id: produto?.id || 0,
                                            nome: produto?.nome || "",
                                            valor: produto?.valor || 0,
                                            quantidade: item.quantidade,
                                            foto: produto?.foto,
                                        }}
                                    />
                                );
                            }
                        }
                    )}
                </Grid>
            </List>
            <Totalizador />
        </div>
    );
};

export default Carrinho;
