import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { MesaBox } from "src/components";
import { Grid, Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import { changeMesa, useEmpresa } from "src/data/api";
import { getEmpresaIdent } from "src/utils";
import { useHistory } from "react-router-dom";
// const useStyles = makeStyles((theme) => ({}));

interface Props {}

const Mesas: React.FC<Props> = (props) => {
    // const classes = useStyles();
    const { data: empresa } = useEmpresa(getEmpresaIdent());
    const history = useHistory();

    const onSelectMesa = (id: number) => {
        (async () => {
            //Troca Mesa
            await changeMesa(id);
            history.push("./pedido/novo");
        })();
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">Selecione a mesa:</Typography>
            </Grid>
            {/* Renderiza as mesas pela quantidade no useEmpresa e troca o valor de mesa dentro do pedido no localstorage avançando pra tela do pedido */}
            {Array(empresa ? empresa[0].qnt_mesas : 0)
                .fill(0)
                .map((a, i) => (
                    <Grid key={i} item xs={isMobile ? 12 : undefined}>
                        <MesaBox onClick={onSelectMesa} id={i + 1} />
                    </Grid>
                ))}
        </Grid>
    );
};

export default Mesas;
