import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Paper,
    Card,
    CardActionArea,
    Typography,
    Grow,
} from "@material-ui/core";
import Skeleton from "react-loading-skeleton";
import { addToCart } from "src/data/api";
import { useCarrinho } from "src/data/api";
import { toBRL } from "src/utils";

import QtButtons from "./qtButtons";
import { carrinhoInterface, produtoInterface } from "src/data/interfaces";
import { isMobile } from "react-device-detect";

const imageSize: number = isMobile ? 128 : 164;

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        overflow: "hidden",
    },
    productContainer: {
        display: "flex",
        overflow: "hidden",
    },
    productContent: {
        padding: theme.spacing(1),
        width: `calc(100% - ${imageSize}px)`,
        height: "90%",
        display: "grid",
        gridAutoRows: "max-content",
    },
    productImageContainer: {
        width: `${imageSize}px`,
        height: `${imageSize}px`,
        backgroundColor: "#eee",
    },
    productImage: {
        width: imageSize,
        height: imageSize,
    },
    footerContainer: {
        position: "absolute",
        marginTop: isMobile ? theme.spacing(11) : theme.spacing(14),
        marginLeft: theme.spacing(1),
    },
    productFooter: {
        display: "flex",
        alignItems: "flex-end",
    },
    productManipuation: {
        height: "40px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    productValue: {
        marginLeft: theme.spacing(1),
    },
}));

interface Props {
    loading?: boolean;
    item?: produtoInterface;
}

const ProductBox: React.FC<Props> = (props) => {
    const classes = useStyles();
    const [imageLoading, setImageLoading] = useState(true);
    const [quantity, setQuantity] = useState(0);
    const { data: carrinho } = useCarrinho();

    function onAdd() {
        addToCart(props.item?.id || 0, 1);
    }

    function onRemove() {
        if (quantity > 0) {
            addToCart(props.item?.id || 0, -1);
        }
    }

    useEffect(() => {
        setQuantity(0);
        carrinho?.every((item: carrinhoInterface) => {
            if (item.id === props.item?.id) {
                setQuantity(item.quantidade);
                return false;
            }
            return true;
        });
        if(props.item?.foto){
            setImageLoading(false);
        }
    }, [carrinho, props.item]);

    return (
        <Grow in={true}>
            {props.loading ? (
                <Paper className={classes.container}>
                    <div className={classes.productContent}>
                        <Skeleton width={125} />
                        <Skeleton width={75} />
                        <Skeleton width={100} />
                        <div className={classes.productFooter}>
                            <Skeleton width={75} height={30} />
                        </div>
                    </div>
                    <div className={classes.productImage}>
                        <Skeleton width={imageSize} height={imageSize} />
                    </div>
                </Paper>
            ) : (
                <Card className={classes.container}>
                    <CardActionArea
                        onClick={(e) => onAdd()}
                        className={classes.productContainer}
                    >
                        <div className={classes.productContent}>
                            <Typography
                                variant={isMobile ? "body1" : "h6"}
                                style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: isMobile ? "normal" : "nowrap",
                                    fontWeight: isMobile ? 'bold' : 'normal',
                                    wordBreak: 'break-all'
                                }}
                            >
                                {props.item?.nome}
                            </Typography>
                            <Typography variant="body2">
                                Cod. {props.item?.id}
                            </Typography>
                            {isMobile ? null : <Typography variant="body2">SEM EAN</Typography>}
                        </div>
                        <div className={classes.productImageContainer}>
                            {imageLoading ? (
                                <Skeleton
                                    width={imageSize}
                                    height={imageSize}
                                />
                            ) : (
                                <img
                                    alt="Imagem do Produto"
                                    className={classes.productImage}
                                    src={props.item?.foto}
                                />
                            )}
                        </div>
                    </CardActionArea>
                    <div className={classes.footerContainer}>
                        <div className={classes.productFooter}>
                            <div className={classes.productManipuation}>
                                <QtButtons
                                    onAdd={onAdd}
                                    onRemove={onRemove}
                                    quantity={quantity}
                                />
                                <Typography
                                    className={classes.productValue}
                                    variant={isMobile ? "body2" : "h5"}
                                >
                                    {toBRL(props.item?.valor || 0)}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Card>
            )}
        </Grow>
    );
};

ProductBox.defaultProps = {
    loading: false,
};

export default ProductBox;
