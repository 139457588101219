import axios, { AxiosInstance } from "axios";
import { getEmpresaIdent } from "src/utils";
import { showMessage } from 'src/utils/dialogs';

let api_url = "http://fivesysweb.dyndns.org:83/api";
if (process.env.NODE_ENV !== "production") {
    api_url = process.env.REACT_APP_API || "/api";
}


export function api(): AxiosInstance {
    const api_token = localStorage.getItem('token');
    const api = axios.create({
        baseURL: api_url,
        headers: {
            'Authorization': api_token ? `Token ${api_token}` : "",
        },
        params: {
            empresa__ident: getEmpresaIdent()
        }
    })
    // Add a response interceptor
    api.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response?.status === 401) {
            showMessage('Sessão expirada', 'Alerta', () => {
                localStorage.removeItem('token');
                window.location.href = '/';
            });
        }
        return Promise.reject(error);
    });
    return api;
};