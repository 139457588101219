import React from "react";
import { TextField } from "@material-ui/core";
import { IMaskInput } from "react-imask";

//Temporariamente em JSX por que o IMaskInput não tem o Types
const MTextField = (props) => {

    return <TextField {...props}
        InputProps={{
            inputComponent: IMaskInput,
        }}
        inputProps={{
            ...props.maskprops
        }}
    />;
};

export default MTextField;
