import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import { ShoppingCart } from "@material-ui/icons";
import { useCarrinho } from "src/data/api";
import { useHistory } from "react-router-dom";
import { sumBy } from "lodash";

const fabSize: number = 92;
const countPadding: number = 10;

const useStyles = makeStyles((theme) => ({
    container: {
        position: "fixed",
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    fab: {
        width: fabSize,
        height: fabSize,
    },
    icon: {
        width: fabSize / 2,
        height: fabSize / 2,
    },
    itemCount: {
        position: "absolute",
        right: -countPadding,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        top: fabSize / 2 + countPadding,
        fontSize: 28,
        backgroundColor: theme.palette.primary.light,
        borderRadius: "100px",
        color: theme.palette.common.white,
    },
}));

interface Props {}

const CarrinhoFab: React.FC<Props> = (props) => {
    const history = useHistory();
    const { data: carrinho } = useCarrinho();
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Fab
                color="primary"
                className={classes.fab}
                onClick={() => history.push("/carrinho")}
            >
                <ShoppingCart className={classes.icon} />
            </Fab>
            <div className={classes.itemCount}>
                {sumBy(carrinho, "quantidade")}
            </div>
        </div>
    );
};

export default CarrinhoFab;
