import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    IconButton,
    LinearProgress,
    Typography,
} from "@material-ui/core";
import { ArrowBack, Restaurant } from "@material-ui/icons";
import { AppBar, MesaBox, ToolBarSpace } from "src/components";
import { useHistory } from "react-router";
import { useMesasAbertas } from "src/data/api";
import { isMobile } from "react-device-detect";
const useStyles = makeStyles((theme) => ({
    appBarTitle: {
        marginLeft: theme.spacing(2),
    },
    listContainer: {
        paddingRight: theme.spacing(0.5),
        marginTop: theme.spacing(2),
    },
    pedidobar: {
        marginBottom: theme.spacing(1.5),
    },
}));

interface Props {}

const MesasList: React.FC<Props> = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const { data: mesas, isValidating: is_syncing } = useMesasAbertas();

    return (
        <div>
            <AppBar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => history.push("/")}
                >
                    <ArrowBack />
                </IconButton>
                <Restaurant />
                <div className={classes.appBarTitle}>
                    <Typography variant="h6">Mesas</Typography>
                </div>
            </AppBar>
            <ToolBarSpace />
            <Grid item xs={12}>
                <Typography variant="h5">
                    Selecione a mesa para visualizar seu consumo
                </Typography>
            </Grid>
            <div className={classes.listContainer}>
                <Grid container spacing={2}>
                    {is_syncing ? <LinearProgress color="primary" /> : null}
                    {mesas?.map((mesa: any, key: number) => {
                        return (
                            <Grid key={key} item xs={isMobile ? 12 : undefined}>
                                <MesaBox
                                    onClick={() =>
                                        history.push(`/mesas/${mesa.numero}/`)
                                    }
                                    id={mesa.numero}
                                    consumo={mesa.valor}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </div>
    );
};

export default MesasList;
