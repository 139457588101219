import useSWR, { mutate } from "swr";
import { userInterface, carrinhoInterface } from "./interfaces";
import { api } from ".";

const pedidoDefault = '{"items": [], "endereco": null}';

export const addToCart = (id: number, quantidade: number) => {
    let pedido = JSON.parse(localStorage.getItem("pedido") || pedidoDefault);
    let hasFound = false;
    pedido.items.every((item: carrinhoInterface, key: number) => {
        if (item.id === id) {
            item.quantidade += quantidade;
            if (item.quantidade === 0) {
                pedido.items.splice(key, 1);
            }
            hasFound = true;
            return false;
        }
        return true;
    });
    if (!hasFound) {
        pedido.items.push({ id, quantidade });
    }
    localStorage.setItem("pedido", JSON.stringify(pedido));
    mutate("cart", pedido.items);
};

export const changeEndereco = (end: number) => {
    let pedido = JSON.parse(localStorage.getItem("pedido") || pedidoDefault);
    pedido.endereco = end;
    localStorage.setItem("pedido", JSON.stringify(pedido));
    mutate("ped/novo", pedido);
};

export const changeMesa = (mesa: number) => {
    let pedido = JSON.parse(localStorage.getItem("pedido") || pedidoDefault);
    pedido.mesa = mesa;
    localStorage.setItem("pedido", JSON.stringify(pedido));
    mutate("ped/novo", pedido);
};

export function useCliente(id: number) {
    const { data, error } = useSWR(`cli=${id}`, async () => {
        if (id) {
            const cliente = await api().get(`/user/${id}/`);
            return cliente.data;
        } else {
            return null;
        }
    });
    return { data, error };
}

export const useCarrinho = () => {
    return useSWR("cart", () => {
        const pedido = JSON.parse(
            localStorage.getItem("pedido") || pedidoDefault
        );
        return pedido.items;
    });
};

export function useMesasAbertas() {
    const { data, error, isValidating } = useSWR(
        "mesasab",
        async () => {
            try {
                const mesas = await api().get("/mesas/abertos/");
                return mesas.data.rows;
            } catch (error) {
                return [];
            }
        },
        {
            refreshInterval: 5000,
            refreshWhenHidden: true,
            errorRetryInterval: 2000,
        }
    );
    return { data, error, isValidating };
}

export function usePedidosAbertos() {
    const { data, error, isValidating } = useSWR(
        "pedab",
        async () => {
            try {
                const pedidos = await api().get("/pedido/abertos/");
                return pedidos.data.rows;
            } catch (error) {
                return [];
            }
        },
        {
            refreshInterval: 5000,
            refreshWhenHidden: true,
            errorRetryInterval: 2000,
        }
    );
    return { data, error, isValidating };
}

export const usePedido = (id: number) => {
    return useSWR(
        `ped/${id}`,
        async () => {
            if (Number(id)) {
                const pedido = await api().get(`/pedido/${id}/`);
                return pedido.data;
            } else {
                const pedido = JSON.parse(
                    localStorage.getItem("pedido") || pedidoDefault
                );
                return pedido;
            }
        },
        { refreshInterval: 5000 }
    );
};
export const useMesa = (id: number) => {
    return useSWR(
        `ped/${id}`,
        async () => {
            if (Number(id)) {
                const mesa = await api().get(`/mesas/${id}/`);
                return mesa.data;
            } else {
                const mesa = JSON.parse(localStorage.getItem("mesa") || "{}");
                return mesa;
            }
        },
        { refreshInterval: 5000 }
    );
};

export const logout = () => {
    localStorage.removeItem("token");
    mutate("user", undefined);
};

export const login = async (user: userInterface) => {
    try {
        const token = await api().post("/obtain-auth-token/", {
            username: user.username,
            password: user.password,
        });
        if (token?.data?.token) {
            await localStorage.setItem("token", token.data.token);
            const user = await api().get("/obtain-logged-user/");
            mutate("user", user.data);
        }
        return token?.data;
    } catch (error) {
        return undefined;
    }
};

export const register = async (user: userInterface) => {
    const token = await api().post("/register/", {
        username: user.username,
        password: user.password,
        email: user.email,
        telefone: user.telefone,
        cpf: user.cpf,
        nome: user.nome,
        registered_in: user.registered_in,
    });
    if (token?.data?.token) {
        await localStorage.setItem("token", token.data.token);
        const user = await api().get("/obtain-logged-user/");
        mutate("user", user.data);
    }
    return token?.data;
};

export const useLoggedUser = () => {
    return useSWR("user", async () => {
        if (localStorage.getItem("token")) {
            const user = await api().get("/obtain-logged-user/");
            return user.data;
        }
    });
};

export const useProdutos = (params?: object) => {
    return useSWR(`prod?${JSON.stringify(params)}`, async () => {
        return (
            await api().get("/produto/", {
                params,
            })
        ).data.rows;
    });
};

export const useEnderecos = (id: number | string = "") => {
    return useSWR(`endereco/${id}`, async () => {
        if (id === null) {
            return undefined;
        } else {
            const data = await api().get(`/endereco/${id}`);
            return data.data.rows || data.data;
        }
    });
};

export const useEmpresa = (ident: number | string = "") => {
    return useSWR(`empresa/?ident=${ident}`, async () => {
        const data = await api().get(`/empresa/`, {
            params: {
                ident,
            },
        });
        return data.data.rows || data.data;
    });
};

export const useGrupos = () => {
    return useSWR("grupo", async () => {
        return (await api().get("/grupo")).data.rows;
    });
};
