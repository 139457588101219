import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { MainSearchBar, ProductBox } from "src/components";
import { CarrinhoFab } from "src/components";
import { useProdutos } from "src/data/api";
import { produtoInterface } from "src/data/interfaces";
import { useParams } from "react-router-dom";
import useMainContext from "src/utils/AppContext";
import { randomArray } from "src/utils";
import { isNumber, debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
    MainWrapper: {
        width: "100%",
    },
    SearchContainer: {
        marginBottom: theme.spacing(2),
    },
    DetailsContainer: {},
    DetailsCompanyName: {
        marginBottom: theme.spacing(1),
    },
    DetailsProducts: {
        marginTop: theme.spacing(2),
    },
}));

const Grupo: React.FC = (props) => {
    const classes = useStyles();
    const [pesquisa, setPesquisa] = useState("");
    const [inputPesquisa, setInputPesquisa] = useState("");
    const { grupo_id } = useParams();
    const { data: produtos } = useProdutos({
        search: pesquisa,
        grupo: grupo_id,
    });
    const [context, setContext] = useMainContext();

    useEffect(() => {
        if (Number(grupo_id) !== context.currentGroup) {
            setContext({ currentGroup: Number(grupo_id) });
        }
    }, [context.currentGroup, grupo_id, setContext]);

    const debouncedPesquisa = React.useCallback(
        debounce(
            (nextValue: React.SetStateAction<string>) => setPesquisa(nextValue),
            1000
        ),
        []
    );

    const onPesquisa = (e: React.ChangeEvent<HTMLInputElement>) => {
        const aValue = e.target.value;
        setInputPesquisa(aValue);
        debouncedPesquisa(aValue);
    };

    return (
        <div className={classes.MainWrapper}>
            <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.SearchContainer}
            >
                <Grid item xs={10} md={8} lg={6} xl={4}>
                    <MainSearchBar
                        onChange={onPesquisa}
                        value={inputPesquisa}
                    />
                </Grid>
            </Grid>
            <div className={classes.DetailsProducts}>
                <Grid container spacing={3}>
                    {(produtos ? produtos : randomArray(20)).map(
                        (item: produtoInterface, key: number) => {
                            return (
                                <Grid
                                    key={key}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={4}
                                >
                                    <ProductBox
                                        item={item}
                                        loading={isNumber(item)}
                                    />
                                </Grid>
                            );
                        }
                    )}
                </Grid>
            </div>
            <CarrinhoFab />
        </div>
    );
};

export default Grupo;
