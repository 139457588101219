import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase, Paper, Typography } from "@material-ui/core";
import { Restaurant } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import { toBRL } from "src/utils";
const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
    },
    wrapper: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    wrapperMobile: {
        flexDirection: "row",
    },
    icon: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        color: theme.palette.primary.main,
        fontSize: 80,
    },
    text: {
        paddingBottom: theme.spacing(1),
        color: theme.palette.primary.main,
        fontWeight: "bold",
        width: "100%",
        textAlign: "center",
        alignSelf: "auto",
    },
    textMobile: {
        paddingBottom: 0,
        textAlign: "left",
        alignSelf: "center",
    },
}));

interface Props {
    id: number;
    onClick(id: number): void;
    forceMobile?: boolean;
    disabled?: boolean;
    consumo?: number;
}

const MesaBox: React.FC<Props> = (props) => {
    const classes = useStyles();
    return (
        <ButtonBase
            className={classes.container}
            onClick={() => props.onClick(props.id)}
            disabled={props.disabled}
        >
            <Paper
                className={`${classes.wrapper} ${
                    isMobile || props.forceMobile ? classes.wrapperMobile : null
                }`}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection:
                            isMobile || props.forceMobile ? "row" : "column",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingRight: 20,
                        alignItems: "center",
                    }}
                >
                    <Restaurant className={classes.icon} />
                    <Typography
                        className={`${classes.text} ${
                            isMobile || props.forceMobile
                                ? classes.textMobile
                                : null
                        }`}
                        variant="h4"
                    >
                        {props.id}
                    </Typography>
                    {props.consumo && props.consumo !== 0 && (
                        <Typography
                            className={`${classes.text} ${
                                isMobile || props.forceMobile
                                    ? classes.textMobile
                                    : null
                            }`}
                            variant={
                                isMobile || props.forceMobile ? "h5" : "h6"
                            }
                        >
                            {toBRL(props.consumo || 0)}
                        </Typography>
                    )}
                </div>
            </Paper>
        </ButtonBase>
    );
};

MesaBox.defaultProps = {
    forceMobile: false,
    disabled: false,
};

export default MesaBox;
