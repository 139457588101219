import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { MainSearchBar, ProductBox } from "src/components";
import { CarrinhoFab } from "src/components";
import { useProdutos, useEmpresa } from "src/data/api";
import { produtoInterface } from "src/data/interfaces";
import useMainContext from "src/utils/AppContext";
import { getEmpresaIdent, randomArray } from "src/utils";
import Skeleton from "react-loading-skeleton";
import { isNumber, debounce } from "lodash";

const useStyles = makeStyles((theme) => ({
    MainWrapper: {
        width: "100%",
    },
    SearchContainer: {
        marginBottom: theme.spacing(2),
    },
    DetailsContainer: {},
    DetailsCompanyName: {
        marginBottom: theme.spacing(1),
    },
    DetailsProducts: {
        marginTop: theme.spacing(2),
    },
}));

const Inicio: React.FC = (props) => {
    const classes = useStyles();
    const [pesquisa, setPesquisa] = useState("");
    const [inputPesquisa, setInputPesquisa] = useState("");
    const { data: produtos } = useProdutos({
        search: pesquisa,
        destaque: true,
    });
    const { data: empresa } = useEmpresa(getEmpresaIdent());
    const [, setContext] = useMainContext();

    useEffect(() => {
        setContext({ currentGroup: null });
        // eslint-disable-next-line
    }, []);

    const debouncedPesquisa = React.useCallback(
        debounce(
            (nextValue: React.SetStateAction<string>) => setPesquisa(nextValue),
            1000
        ),
        []
    );

    const onPesquisa = (e: React.ChangeEvent<HTMLInputElement>) => {
        const aValue = e.target.value;
        setInputPesquisa(aValue);
        debouncedPesquisa(aValue);
    };

    return (
        <div className={classes.MainWrapper}>
            <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.SearchContainer}
            >
                <Grid item xs={10} md={8} lg={6} xl={4}>
                    <MainSearchBar
                        onChange={onPesquisa}
                        value={inputPesquisa}
                    />
                </Grid>
            </Grid>
            <div className={classes.DetailsContainer}>
                <Typography variant="h4" className={classes.DetailsCompanyName}>
                    {empresa ? empresa[0]?.nome : <Skeleton />}
                </Typography>
                <Typography variant="h5">Produtos em destaque</Typography>
                <Typography variant="body1">
                    Use a barra de pesquisa em cima da tela, ou ao lado para
                    procurar
                </Typography>
            </div>
            <div className={classes.DetailsProducts}>
                <Grid container spacing={3}>
                    {(produtos ? produtos : randomArray(20)).map(
                        (item: produtoInterface, key: number) => {
                            return (
                                <Grid
                                    key={key}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={6}
                                    xl={4}
                                >
                                    <ProductBox
                                        item={item}
                                        loading={isNumber(item)}
                                    />
                                </Grid>
                            );
                        }
                    )}
                </Grid>
            </div>
            <CarrinhoFab />
        </div>
    );
};

export default Inicio;
