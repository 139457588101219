import React from "react";
import { useTheme } from "@material-ui/core/styles";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    const theme = useTheme();
    return (
        <svg
            width={223}
            height={134}
            viewBox="0 0 223 134"
            fill="none"
            {...props}
        >
            <path
                d="M15.416 125.736l13.152-18.878L15.41 127.79l-.007 2.148a55.562 55.562 0 01-2.786-.092c-.004-.487 1.114-27.321 1.067-27.807.065-.018.1-2.538.127-2.648L.07 78.76l13.754 18.677.041.552.816-20.848-11.81-21.35L14.774 73.48c.004-.284.563-43.09.569-43.355.003.14.26 34.066.262 34.209L26.9 50.678l-11.306 16.59-.058 18.659 10.482-18.044C12.938 94.197 15.833 84.989 15.5 99.045l15.226-25.124-15.24 28.744-.069 23.071zM216.62 128.3l5.481-7.867-5.483 8.722-.004.896a23.36 23.36 0 01-1.16-.039c-.002-.203.464-11.385.444-11.588.027-.007.042-1.057.053-1.103l-5.726-8.597 5.732 7.783.017.23.34-8.688-4.922-8.897 4.961 7.371.237-18.067c.002.058.109 14.196.109 14.255l4.708-5.69-4.712 6.913-.024 7.776 4.368-7.519c-5.451 10.965-4.245 7.128-4.384 12.986l6.345-10.47-6.351 11.978-.029 9.615z"
                fill="#D0CDE1"
            />
            <path
                d="M111.136 134c61.378 0 111.135-1.948 111.135-4.35 0-2.402-49.757-4.35-111.135-4.35C49.757 125.3 0 127.248 0 129.65c0 2.402 49.757 4.35 111.136 4.35z"
                fill="#D0CDE1"
            />
            <path
                d="M165.045 51.378H38.151v75.58h126.894v-75.58z"
                fill="#3F3D56"
            />
            <path
                d="M69.555 51.378a30.302 30.302 0 01-9.058 21.115 30.36 30.36 0 01-22.346 8.688V51.378h31.404zM107.805 126.957H95.736a6.424 6.424 0 016.035-4.206 6.43 6.43 0 016.034 4.206zM165.045 87.864v25.457a12.754 12.754 0 01-12.134-12.729c0-3.272 1.26-6.419 3.519-8.788a12.76 12.76 0 018.615-3.94z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M141.733 78.841h-80.05a3.737 3.737 0 00-3.74 3.735 3.737 3.737 0 003.74 3.735h80.05a3.737 3.737 0 003.739-3.735 3.737 3.737 0 00-3.739-3.735zM141.733 93.562h-80.05a3.737 3.737 0 00-3.74 3.735 3.737 3.737 0 003.74 3.735h80.05a3.737 3.737 0 003.739-3.735 3.737 3.737 0 00-3.739-3.735zM141.733 108.282h-23.21a3.737 3.737 0 00-3.739 3.735 3.737 3.737 0 003.739 3.735h23.21a3.737 3.737 0 003.739-3.735 3.737 3.737 0 00-3.739-3.735z"
                fill="#fff"
            />
            <path
                d="M185.167 23.224s3.49 1.356 3.684 5.81c.193 4.455-.97 16.85-.97 16.85s4.265 6.196.97 7.94c-3.296 1.742-3.684-6.972-3.684-6.972l-3.877-18.398s2.327-5.423 3.877-5.23zM149.499 37.556s-8.336 5.422-8.723 1.162c-.388-4.26 2.52-20.916 2.52-20.916s.387-7.165 4.458-5.422c4.071 1.742-1.163 7.746-1.163 7.746l.582 13.556 3.877-.193-1.551 4.067z"
                fill="#A0616A"
            />
            <path
                d="M162.875 42.591s-25.589-9.49-24.232 1.55c1.357 11.038 7.948 24.982 7.948 24.982l2.326 3.68h4.847l-3.102-19.948s11.05 6.197 14.345 5.23c0 0 4.265 28.468 5.816 30.598 1.55 2.13 4.264 27.887 4.264 27.887l2.52 3.099h4.847V76.095s8.335-27.113 1.938-28.469c-6.397-1.355-21.517-5.035-21.517-5.035z"
                fill="#2F2E41"
            />
            <path
                d="M148.336 70.285s-1.163-2.13-3.102 0c-1.938 2.13-6.591 4.648-6.591 4.648s-10.08 2.324-1.938 4.26c8.142 1.937 9.111 0 9.111 0s1.357.388 2.52.775 8.723-.968 8.723-2.13-2.566-8.106-3.609-7.055c-1.043 1.051-5.114-.498-5.114-.498zM177.979 117.752s-2.858-2.351-3.531-1.68c-.673.672-4.203 5.207-4.203 5.207s-9.416 7.223-.841 8.399c8.575 1.176 8.07-2.016 8.07-2.016h2.69c.337 0 4.876-2.015 4.708-2.855-.168-.84-2.186-7.688-2.858-7.204-.673.485-4.035.149-4.035.149z"
                fill="#2F2E41"
            />
            <path
                d="M152.019 15.865a6.588 6.588 0 006.591-6.584 6.588 6.588 0 00-6.591-6.585 6.588 6.588 0 00-6.591 6.585 6.588 6.588 0 006.591 6.584z"
                fill="#A0616A"
            />
            <path
                d="M156.671 8.506s5.041 4.26 8.53 4.26c3.489 0-5.04 7.941-5.04 7.941l-7.56-6.778 4.07-5.423z"
                fill="#A0616A"
            />
            <path
                d="M158.61 20.707l-.151-1.526s3.834-6.802 4.997-7.383c1.163-.58 1.939-.58 1.939-.58s3.877-4.067 8.141-1.937c4.265 2.13 14.345 15.687 14.345 15.687s-7.172.58-6.203 6.197c0 0 7.173 18.398 5.234 18.592-1.938.193-7.754-1.162-13.763 1.549-6.01 2.711-8.724.968-8.724.968l-3.101-10.07s-5.816-3.293-5.622-6.198l-8.432 3.777.775-7.166 5.137-5.906s-.969-.775 5.428-6.004z"
                fill="#575A89"
            />
            <path
                d="M145.956 9.993c-.262.29-.59.514-.957.651-.375.106-.848-.024-.998-.384l.279-.362-.836-.519.365-.427-.673-.73.531-.42-1.012-.723c1.311-2.137 2.729-4.3 4.798-5.716 2.069-1.417 4.96-1.92 7.08-.58 1.101.695 1.879 1.8 2.502 2.941 1.254 2.298 2.359 4.537 2.522 7.148-.787-.766-1.937-1.198-2.725-1.964a5.867 5.867 0 00-1.175-.961c-.824-.464-1.809-.505-2.731-.714-.776-.176-1.279-.526-1.866-1.025-.697-.594-.726-.364-1.43.305l-3.674 3.48z"
                fill="#2F2E41"
            />
        </svg>
    );
}

export default SvgComponent;
