import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardActionArea, Typography } from "@material-ui/core";
import { LocationOn, Add } from "@material-ui/icons";
import { enderecoInterface } from "src/data/interfaces";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: "flex",
        padding: isMobile ? theme.spacing(2) : theme.spacing(4),
        justifyContent: "flex-start",
    },
    icon: {
        fontSize: isMobile ? 24 : 80,
    },
    infoContainer: {
        marginLeft: isMobile ? theme.spacing(2) : theme.spacing(4),
    },
}));

interface Props {
    new?: boolean;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    endereco?: enderecoInterface;
}

const EnderecoBar: React.FC<Props> = (props) => {
    const classes = useStyles();
    return (
        <div className={props.className}>
            <Card style={{ borderRadius: 22, width: "100%" }}>
                <CardActionArea
                    className={classes.cardContainer}
                    disabled={props.disabled || false}
                    onClick={props.onClick}
                >
                    {props.new ? (
                        <>
                            <Add className={classes.icon} color="primary" />
                            <div className={classes.infoContainer}>
                                <Typography variant={isMobile ? "h6" : "h4"}>
                                    Adicionar novo endereço
                                </Typography>
                            </div>
                        </>
                    ) : (
                        <>
                            <LocationOn
                                className={classes.icon}
                                color="primary"
                            />
                            <div className={classes.infoContainer}>
                                <Typography variant={isMobile ? "body1" : "h5"}>
                                    <b>{props.endereco?.referencia}</b>
                                </Typography>
                                <Typography variant={isMobile ? "body1" : "h6"}>
                                    {props.endereco?.logradouro},{" "}
                                    {props.endereco?.numero}
                                </Typography>
                                <Typography variant={isMobile ? "body1" : "h6"}>
                                    <b>{props.endereco?.cep}</b>,{" "}
                                    {props.endereco?.cidade},{" "}
                                    {props.endereco?.municipio},{" "}
                                    {props.endereco?.complemento}
                                </Typography>
                            </div>
                        </>
                    )}
                </CardActionArea>
            </Card>
        </div>
    );
};

EnderecoBar.defaultProps = {
    new: false,
};

export default EnderecoBar;
