import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { usePedidosAbertos } from "src/data/api";
import {
    Grid,
    LinearProgress,
    IconButton,
    Typography,
} from "@material-ui/core";
import { PedidoBar } from "src/components";
import { ArrowBack, ListAlt } from "@material-ui/icons";
import { AppBar, ToolBarSpace } from "src/components";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme) => ({
    appBarTitle: {
        marginLeft: theme.spacing(2),
    },
    container: {
        height: "100%",
    },
    listContainer: {
        height: "calc(100vh - 90px)",
        paddingRight: theme.spacing(0.5),
    },
    pedidobar: {
        marginBottom: theme.spacing(1.5),
    },
}));

interface Props {}

const PedidoList: React.FC<Props> = (props) => {
    const classes = useStyles();
    const history = useHistory();

    const { data: pedidos, isValidating: is_syncing } = usePedidosAbertos();

    return (
        <div>
            <AppBar>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => history.push("/")}
                >
                    <ArrowBack />
                </IconButton>
                <ListAlt />
                <div className={classes.appBarTitle}>
                    <Typography variant="h6">Pedidos</Typography>
                </div>
            </AppBar>
            <ToolBarSpace />
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    {is_syncing ? <LinearProgress color="primary" /> : null}
                    <div className={classes.listContainer}>
                        {pedidos?.map((ped: any, key: number) => {
                            return (
                                <PedidoBar
                                    onClick={() => history.push(`${ped.id}`)}
                                    className={classes.pedidobar}
                                    key={key}
                                    pedido={ped}
                                />
                            );
                        })}
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default PedidoList;
