import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EnderecoBar from "./enderecoBar";
import { CarrinhoFab } from "src/components";
import { useHistory } from "react-router-dom";
import { useEnderecos, changeEndereco } from "src/data/api";
import { enderecoInterface } from "src/data/interfaces";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: "center",
    },
    list: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: theme.spacing(3),
    },
    listItem: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
}));

interface Props {}

const Endereco: React.FC = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { data: enderecos } = useEnderecos();

    const onSelectEndereco = (id: number) => {
        (async () => {
            //Troca  endereço
            await changeEndereco(id);
            history.push("./pedido/novo");
        })();
    };

    return (
        <div>
            <Typography
                className={classes.title}
                variant={isMobile ? "h5" : "h2"}
            >
                Selecione o endereço para entrega
            </Typography>
            <div className={classes.list}>
                {enderecos?.map((endereco: enderecoInterface, key: number) => {
                    return endereco.id ? (
                        <EnderecoBar
                            key={key}
                            className={classes.listItem}
                            onClick={() => onSelectEndereco(endereco.id || 0)}
                            endereco={endereco}
                        />
                    ) : null;
                })}
                <EnderecoBar
                    className={classes.listItem}
                    new
                    onClick={() => history.push("./endereco/novo")}
                />
            </div>
            <CarrinhoFab />
        </div>
    );
};

export default Endereco;
