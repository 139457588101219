import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, List, ListItem, Typography } from "@material-ui/core";
import { toBRL } from "src/utils";
import {
    carrinhoInterface,
    produtoInterface,
    produtoCarrinho,
} from "src/data/interfaces";
import { useProdutos, useCarrinho } from "src/data/api";
import { sumBy } from "lodash";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 520px)",
    },
    list: {
        overflow: "auto",
        height: "100%",
    },
    listItem: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    subtotalContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

interface Props {
    className?: string;
    produtos?: produtoCarrinho[];
}

const TabItens: React.FC<Props> = (props) => {
    const classes = useStyles();
    const { data: carrinho } = useCarrinho();
    const { data: produtos } = useProdutos({
        id__in: (props.produtos || carrinho)
            ?.map((item: carrinhoInterface) => item.id)
            .join(","),
    });
    const [total, setTotal] = useState(0);

    useEffect(() => {
        const totalV = (props.produtos || carrinho)?.map(
            (item: carrinhoInterface, key: number) => {
                const produto = produtos?.filter(
                    (prod: produtoInterface) => prod.id === item.id
                )[0];
                return {
                    value: (produto?.valor || 0) * item.quantidade,
                };
            }
        );
        setTotal(sumBy(totalV, "value"));
    }, [carrinho, produtos, props.produtos]);

    return (
        <div className={props.className}>
            <Paper className={classes.container}>
                <List className={classes.list}>
                    {(props.produtos || carrinho)?.map(
                        (item: carrinhoInterface, key: number) => {
                            const produto = produtos?.filter(
                                (prod: produtoInterface) => prod.id === item.id
                            )[0];
                            return (
                                <ListItem key={key} divider>
                                    <div className={classes.listItem}>
                                        <Typography>
                                            {item?.quantidade} X {produto?.nome}
                                        </Typography>
                                        <Typography>
                                            {toBRL(
                                                (produto?.valor || 0) *
                                                    item?.quantidade
                                            )}
                                        </Typography>
                                    </div>
                                </ListItem>
                            );
                        }
                    )}
                </List>
                <div className={classes.subtotalContainer}>
                    <Typography variant={isMobile ? "h5" : "h3"}>
                        SubTotal
                    </Typography>
                    <Typography variant={isMobile ? "h5" : "h3"}>
                        <b>{toBRL(total)}</b>
                    </Typography>
                </div>
            </Paper>
        </div>
    );
};

export default TabItens;
