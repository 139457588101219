import React from "react";
import { Grid } from "@material-ui/core";
import LoginForm from "./login";
import RegisterForm from "./register";
import { CarrinhoFab } from "src/components";
import { isMobile } from "react-device-detect";

interface Props {}

const Login: React.FC = () => {
    return (
        <div>
            <Grid container spacing={isMobile ? 1 : 4}>
                <Grid item xs={12} md={12} lg={6} xl={4}>
                    <LoginForm />
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={4}>
                    <RegisterForm />
                </Grid>
            </Grid>
            <CarrinhoFab />
        </div>
    );
};

export default Login;
