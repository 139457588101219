import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, TextField } from "@material-ui/core";
import LoginSVG from "./loginSVG";
import { login, useLoggedUser } from "src/data/api";
import { useParams, useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { showMessage } from "src/utils/dialogs";
import { Button } from "src/components";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: isMobile ? theme.spacing(1) : theme.spacing(4),
    },
    headerContainer: {
        display: "flex",
        marginBottom: theme.spacing(2),
        flexDirection: isMobile ? "column" : "row",
    },
    headerTitle: {
        marginLeft: theme.spacing(2),
    },
    inputContainer: {
        marginBottom: theme.spacing(3),
    },
    input: {
        marginTop: theme.spacing(2),
    },
}));

interface Props {}

const LoginForm: React.FC = () => {
    const classes = useStyles();
    const { data: user } = useLoggedUser();
    const { to } = useParams();
    const history = useHistory();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loadingLogin, setLoadingLogin] = useState(false);

    function doLogin() {
        (async () => {
            setLoadingLogin(true);
            const res = await login({ username, password });
            if (!res) {
                showMessage(
                    "Não foi possível fazer login, verifique suas credenciais."
                );
                setLoadingLogin(false);
            }
        })();
    }

    useEffect(() => {
        if (user) {
            if (to) {
                history.push("/" + (to as String).replace(".", "/"));
            } else {
                if (user?.pedido_atual) {
                    history.push(`/pedido/${user?.pedido_atual?.id}`);
                } else {
                    history.push("/");
                }
            }
        }
        //eslint-disable-next-line
    }, [user]);

    return (
        <div>
            <Paper className={classes.container}>
                <div className={classes.headerContainer}>
                    <LoginSVG />
                    <div className={classes.headerTitle}>
                        <Typography variant="h5">
                            Clientes cadastrados
                        </Typography>
                        <Typography variant="body1">
                            Para clientes que já possuem cadastro ativo e acesso
                            ao site.
                        </Typography>
                    </div>
                </div>
                <div className={classes.inputContainer}>
                    <TextField
                        label="Email"
                        fullWidth
                        variant="filled"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        className={classes.input}
                        label="Senha"
                        fullWidth
                        variant="filled"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                    />
                </div>
                <div>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={doLogin}
                        loading={loadingLogin}
                    >
                        Entrar
                    </Button>
                    <Button
                        className={classes.input}
                        size="large"
                        fullWidth
                        color="primary"
                        disabled
                    >
                        Esqueceu sua senha?
                    </Button>
                </div>
            </Paper>
        </div>
    );
};

export default LoginForm;
