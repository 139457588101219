import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { ThemeProvider, makeStyles } from "@material-ui/core/styles";

import { ContextProvider } from "src/utils/AppContext";
import { light } from "src/utils/theme";
import { Dialog, Layout } from "src/components";
import { useDialog } from "src/utils/hooks";
import { dialogInterface } from "src/data/interfaces";

import {
    Inicio,
    Carrinho,
    Login,
    Endereco,
    Pedido,
    PedidoList,
    NovoEndereco,
    SelectMesa,
    Grupo,
    PedidoShow,
    MesasList,
    MesasShow,
} from "src/pages/index";

const AppDefaultConstants = {
    version: "1.0.0",
    currentGroup: null,
};

const Home: React.FC = (props) => {
    const { data: _dialog } = useDialog();
    const [dialog, setDialog] = useState<dialogInterface | undefined>(
        undefined
    );

    useEffect(() => {
        if (_dialog?.open) {
            setDialog(_dialog);
        }
    }, [_dialog]);

    function closeDialogLocal() {
        setDialog({ ...dialog, open: false });
    }

    return (
        <Layout>
            <Switch>
                <Route exact path="/" component={Inicio} />
                <Route exact path="/carrinho" component={Carrinho} />
                <Route path="/login/:to" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/endereco" component={Endereco} />
                <Route exact path="/mesa" component={SelectMesa} />
                <Route exact path="/endereco/novo" component={NovoEndereco} />
                <Route path="/pedido/:id" component={Pedido} />
                <Route path="/pedidos/:id" component={PedidoShow} />
                <Route path="/pedidos" component={PedidoList} />
                <Route path="/mesas/:id" component={MesasShow} />
                <Route path="/mesas" component={MesasList} />
                <Route path="/grupo/:grupo_id" component={Grupo} />
                <Redirect to="/" />
            </Switch>
            <Dialog
                message={dialog?.message}
                title={dialog?.title}
                open={dialog?.open}
                setOpen={closeDialogLocal}
                setConfirm={dialog?.func}
                type={dialog?.type}
                inputProps={dialog?.inputProps}
            />
        </Layout>
    );
};

const useStyles = makeStyles((theme) => ({
    App: {
        backgroundColor: theme.palette.background.default,
        height: "100%",
    },
}));

const App: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.App}>
            <ThemeProvider theme={light}>
                <ContextProvider defaultContext={AppDefaultConstants}>
                    <BrowserRouter basename="/">
                        <Switch>
                            <Route path="/" component={Home} />
                        </Switch>
                    </BrowserRouter>
                </ContextProvider>
            </ThemeProvider>
        </div>
    );
};

export default App;
