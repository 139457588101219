import React from "react";
import { useTheme } from "@material-ui/core/styles";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
    const theme = useTheme();
    return (
        <svg
            width={181}
            height={163}
            viewBox="0 0 181 163"
            fill="none"
            {...props}
        >
            <path
                d="M115.05 94.341L90.07 64.66l-50.144.458L9.51 94.708l.614.395h-.219v61.55h105.078v-61.55l.068-.762z"
                fill="#3F3D56"
            />
            <path
                d="M90.095 64.723l-31.207 36.543v55.388h56.094v-62.34L90.095 64.723z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M92.86 126.023H81.406v10.113H92.86v-10.113zM92.86 108.518H81.406v9.953H92.86v-9.953zM41.95 123.339H30.494v10.114H41.95v-10.114zM41.95 105.834H30.494v9.954H41.95v-9.954z"
                fill="#fff"
            />
            <path d="M181 156.461H0v.431h181v-.431z" fill="#3F3D56" />
            <path
                d="M153.741 149.665l.862 4.302-4.307.86.646-5.377 2.799.215zM167.308 148.804l-1.077 5.377h-5.168l2.154-6.452 4.091 1.075z"
                fill="#A0616A"
            />
            <path
                d="M172.046 111.379l.861 5.807-1.077 12.691-.861 4.732-3.661 15.486s-4.092 0-4.738-1.721l1.077-8.818s.862-7.098.646-7.959c-.215-.86-3.445-14.626-3.445-14.626s-4.738 21.724-5.384 23.66c-.646 1.936-1.508 10.324-1.508 10.324s-3.66.645-3.876 0c-.215-.645-.43-15.916-.43-18.282 0-2.366.43-3.657.43-4.087 0-.43-1.696-20.965-1.696-20.965l23.662 3.758zM163.216 153.106s1.077.215 1.723 0c.646-.215 0-1.506 0-1.506h2.369s2.584 5.808 3.661 6.238c1.077.43 4.522 5.162.431 5.162a13.377 13.377 0 01-7.107-2.151s-.431-1.936-2.369-2.151c-1.938-.215-3.23-2.581-3.23-2.581l2.8-5.162s.646 2.366 1.722 2.151zM153.095 152.891s-.431.86-.861.43c-.431-.43-.862-1.506-.862-1.506s-1.292-.645-1.507 0c-.216.645-1.077 4.087-1.508 4.517-.43.43-4.953 4.947-.43 5.807 4.522.861 5.383-1.075 5.383-1.075s-.646-1.075.646-1.506c1.293-.43 1.508-1.935 1.508-1.935l-.862-6.453s-.861 2.151-1.507 1.721z"
                fill="#2F2E41"
            />
            <path
                d="M176.999 96.323l.646 11.184s3.015 7.098-1.077 6.883c-4.092-.215-1.507-7.098-1.507-7.098l-4.006-9.982 5.944-.987zM143.835 93.097l-.646 11.184s-3.015 7.098 1.077 6.883c4.091-.215 1.507-7.098 1.507-7.098l4.006-9.982-5.944-.987zM161.063 68.791a5.596 5.596 0 005.599-5.592 5.596 5.596 0 00-5.599-5.592 5.596 5.596 0 00-5.599 5.592 5.596 5.596 0 005.599 5.593z"
                fill="#A0616A"
            />
            <path
                d="M165.478 65.458s-1.508 6.023 1.507 7.528c3.015 1.506-9.475 3.657-10.337 0l.431-1.72s1.723-2.151 1.292-4.087l7.107-1.721z"
                fill="#A0616A"
            />
            <path
                d="M162.14 73.093s-3.716.347-4.873-2.085c0 0-8.694.58-10.632 8.538l3.23 20.648s-3.446 6.023-3.015 6.668c0 0-2.153 6.023 17.659 6.238l8.613-.43a11.947 11.947 0 00-.215-3.657c-.431-1.936-2.799-15.486-2.799-15.486s6.46-10.11 5.814-11.615c-.646-1.506-1.681-7.442-9.993-9.851 0 0-.128 1.247-3.789 1.032z"
                fill="#E6E6E6"
            />
            <path
                d="M173.122 78.255l2.8 3.657s2.799 15.271 1.938 15.486c-.861.215-6.03 1.936-6.891 1.29-.861-.645-4.738-13.335-4.738-13.335l6.891-7.098zM150.296 77.18l-3.661 2.366s-5.384 15.056-3.877 15.701c1.508.646 9.906.216 9.906.216l-2.368-18.283z"
                fill="#E6E6E6"
            />
            <path
                d="M167.183 58.195s-1.939-4.197-5.656-3.229c-3.717.969-5.818 2.422-5.98 3.874-.162 1.453.081 3.632.081 3.632s.404-2.986 2.99-2.34c1.375.343 3.162.367 4.533.314a1.987 1.987 0 011.462.558 1.974 1.974 0 01.599 1.444l-.068 4.623s5.384-3.441 2.039-8.876z"
                fill="#2F2E41"
            />
            <path
                d="M140.775 59.822H64.717a1.411 1.411 0 01-1.408-1.406V23.535a1.408 1.408 0 011.408-1.407h76.058a1.41 1.41 0 011.409 1.407v34.88a1.41 1.41 0 01-1.409 1.407zM64.717 22.691a.847.847 0 00-.845.844v34.88a.845.845 0 00.845.845h76.058a.845.845 0 00.845-.844V23.535a.846.846 0 00-.845-.844H64.717z"
                fill="#E6E6E6"
            />
            <path
                d="M75.972 40.215a5.734 5.734 0 005.737-5.73 5.734 5.734 0 00-5.737-5.731 5.734 5.734 0 00-5.738 5.73 5.734 5.734 0 005.738 5.73z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M89.222 30.664a.955.955 0 100 1.91h45.08a.957.957 0 00.956-.955.956.956 0 00-.956-.955h-45.08zM89.222 36.395a.957.957 0 00-.884 1.32.953.953 0 00.884.59h19.398a.962.962 0 00.677-.28.952.952 0 00-.31-1.558.962.962 0 00-.367-.072H89.222zM71.571 45.556c-.727 0-1.32.428-1.32.955 0 .526.593.955 1.32.955h62.196c.727 0 1.319-.429 1.319-.955 0-.527-.592-.955-1.319-.955H71.571zM71.571 51.286c-.727 0-1.32.429-1.32.955 0 .527.593.955 1.32.955h62.196c.727 0 1.319-.428 1.319-.955 0-.526-.592-.955-1.319-.955H71.571z"
                fill="#E6E6E6"
            />
            <path
                d="M127.077 20.383c5.635 0 10.203-4.563 10.203-10.192C137.28 4.563 132.712 0 127.077 0c-5.636 0-10.204 4.563-10.204 10.191 0 5.629 4.568 10.192 10.204 10.192z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M127.077 26.758l-3.191-5.519-3.19-5.52h12.762l-3.191 5.52-3.19 5.52z"
                fill={theme.palette.primary.main}
            />
            <path
                d="M127.159 13.784a3.659 3.659 0 003.661-3.656 3.659 3.659 0 00-3.661-3.657 3.659 3.659 0 00-3.661 3.657 3.659 3.659 0 003.661 3.656z"
                fill="#fff"
            />
            <path
                d="M165.575 64.275c.476 0 .861-.482.861-1.076 0-.593-.385-1.075-.861-1.075s-.861.481-.861 1.075.385 1.076.861 1.076z"
                fill="#A0616A"
            />
        </svg>
    );
}

export default SvgComponent;
